import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import BaseOtelMixin from '@/modules/cars/modules/open-telemetry/mixins/common/base-otel.mixin';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import CarsCategoryManagerService, { CarsCategoryManagerServiceS } from '@/modules/cars-category-manager/cars-category-manager.service';

/**
 * Mixin for rates pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class CarMappingPageOtelLogs extends Mixins(BaseOtelMixin) {
    @Inject(CarsCategoryManagerServiceS) private carsCategoryManagerService!: CarsCategoryManagerService;

    static readonly spanPrefix = LOGTYPE.LOADING;

    beforeMount() {
        this.startSpan(CarMappingPageOtelLogs.spanPrefix);
    }

    mounted() {
        this.addEvent('mounted', CarMappingPageOtelLogs.spanPrefix);
        this.carsCategoryManagerService.storeState.loading.whenLoadingFinished()
            .then(_ => {
                this.addEvent('gotRespnose', CarMappingPageOtelLogs.spanPrefix);
                this.endSpan(CarMappingPageOtelLogs.spanPrefix, { sendLogs: true });
            });
    }
}
