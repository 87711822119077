




import { Component } from 'vue-property-decorator';
import CarMappingPageOtelLogs from '@/modules/cars/modules/open-telemetry/mixins/car-mapping-page-otel-log';
import CarsTypeMappingPopup from '@/modules/cars-category-manager/components/cars-category-mapping.popup.vue';

@Component({
    components: {
        CarsTypeMapping: CarsTypeMappingPopup,
    },
})
export default class CarMappingModalPage extends CarMappingPageOtelLogs {}

